<template>
	<div>
		<!-- <div class="home__header">
			<Header/>
		</div> -->
		<div class="home__banner">
			<Banner />
		</div>
		<MobileNav />
		<div class="home__body">
			<Products :detailText="desc" :isHomePage="true" />
			<Concept />
      <ShopInfo/>
			<Instagram />
		</div>
	</div>
</template>

<script>
	import Banner from "./Banner.vue";
	import Products from "./Products.vue";
	import Instagram from "./InstagramGallery.vue";
	import MobileNav from "./MobileNav.vue";
	import Concept from "./Concept.vue";
  import ShopInfo from "./ShopInfo.vue";

	export default {
		name: "HomeContents",
		components: { Banner, Products, Concept, Instagram, MobileNav, ShopInfo },
		data() {
			return {
				desc: "ここにテキストが入ります。",
			};
		},
	};
</script>

<style lang="scss">
	@import "HomeContents.scss";
</style>
