<template>
	<div class="floatingButton">
		<div class="floatingButton__container">
			<a
				href="https://forms.gle/Hd3kQD2Cf3ctdnU86"
				target="_blank"
				rel="noopener noreferrer"
				class="floatingButton__button"
				@click="toTop"
				v-show="scY > 500 && window.width < 510"
			>
				注文はこちらへ
			</a>
		</div>
	</div>
</template>

<script>
	export default {
		name: "FloatingButton",
		data() {
			return {
				scTimer: 0,
				scY: 0,
				window: {
					width: 0,
				},
			};
		},
		mounted() {
			window.addEventListener("scroll", this.handleScroll);
		},
		created() {
			window.addEventListener("resize", this.handleResize);
			this.handleResize();
		},
		destroyed() {
			window.removeEventListener("resize", this.handleResize);
		},
		methods: {
			handleScroll() {
				if (this.scTimer) return;
				this.scTimer = setTimeout(() => {
					this.scY = window.scrollY;
					clearTimeout(this.scTimer);
					this.scTimer = 0;
				}, 100);
			},
			toTop() {
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			},
			handleResize() {
				this.window.width = window.innerWidth;
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "FloatButton.scss";
</style>
