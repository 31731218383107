<template>
	<div class="productlist">
		<MobileNav />
		<div class="productlist__body">
			<Products :isHomePage="false" />
		</div>
		<!-- <div class="ctabutton">
			<div class="ctabutton__container">
				<a
					href="https://forms.gle/Hd3kQD2Cf3ctdnU86"
					target="_blank"
					rel="noopener noreferrer"
					class="ctabutton__button"
				>
					注文はこちらへ
				</a>
			</div>
		</div> -->
	</div>
</template>

<script>
	import Products from "./Products.vue";
	import MobileNav from "./MobileNav.vue";
	export default {
		name: "ProductListContents",
		components: { Products, MobileNav },
	};
</script>

<style lang="scss">
	@import "ProductListContent.scss";
</style>
