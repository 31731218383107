<template>
	<div class="card">
		<router-link v-if="hasSubPage" :to="`/productlist/${item.id}`">
			<div class="card__thumbnail">
				<img
					v-bind:src="item.item_image.url"
					alt=""
					class="card__thumbnail__img"
				/>
			</div>
			<div class="card__info">
				<div class="card__info__content">
					<div
						class="card__tag "
						v-bind:class="{
							'card__tag--soldout': item.item_availability[0] === 'SOLD OUT',
							'card__item_availability[0]--soon':
								item.item_availability[0] === 'COMING SOON',
						}"
					>
						{{ item.item_availability[0] }}
					</div>
					<div class="card__title">{{ item.item_name }}</div>
					<div class="card__detail" v-if="item.item_description">
						<p>{{ item.item_description }}</p>
					</div>
					<div class="card__price" v-if="item.item_price">
						{{ productPrice }}
					</div>
					<div class="card__underline"></div>
				</div>
			</div>
		</router-link>
		<a
			v-else-if="this.item.item_url"
			:href="`${this.item.item_url}`"
			target="_blank"
			rel="noopener noreferrer"
		>
			<div class="card__thumbnail">
				<img
					v-bind:src="item.item_image.url"
					alt=""
					class="card__thumbnail__img"
				/>
			</div>
			<div class="card__info">
				<div class="card__info__content">
					<div
						class="card__tag "
						v-bind:class="{
							'card__tag--soldout': item.item_availability[0] === 'SOLD OUT',
							'card__item_availability[0]--soon':
								item.item_availability[0] === 'COMING SOON',
						}"
					>
						{{ item.item_availability[0] }}
					</div>
					<div class="card__title">{{ item.item_name }}</div>
					<div class="card__detail" v-if="item.item_description">
						<p>{{ item.item_description }}</p>
					</div>
					<div class="card__price" v-if="item.item_price">
						{{ productPrice }}
					</div>
					<div class="card__underline"></div>
				</div>
			</div>
		</a>
    <!-- https://forms.gle/Hd3kQD2Cf3ctdnU86 -->
		<a
			v-else
			target="_blank"
			rel="noopener noreferrer"
		>
			<div class="card__thumbnail">
				<img
					v-bind:src="item.item_image.url"
					alt=""
					class="card__thumbnail__img"
				/>
			</div>
			<div class="card__info">
				<div class="card__info__content">
					<div
						class="card__tag "
						v-bind:class="{
							'card__tag--soldout': item.item_availability[0] === 'SOLD OUT',
							'card__item_availability[0]--soon':
								item.item_availability[0] === 'COMING SOON',
						}"
					>
						{{ item.item_availability[0] }}
					</div>
					<div class="card__title">{{ item.item_name }}</div>
					<div class="card__detail" v-if="item.item_description">
						<p>{{ item.item_description }}</p>
					</div>
					<div class="card__price" v-if="item.item_price">
						{{ productPrice }}
					</div>
					<div class="card__underline"></div>
				</div>
			</div>
		</a>
	</div>
</template>

<script>
	export default {
		name: "Card",
		props: {
			item: Object,
			inHomePage: Boolean,
		},
		data() {
			return {
				price: "",
				hasSubPage: null,
			};
		},
		mounted() {
			if (this.inHomePage && this.item.item_products === null) {
				this.hasSubPage = true;
			} else if (this.inHomePage && this.item.item_products.length !== null) {
				this.hasSubPage = this.item.item_products.length;
			}
		},
		computed: {
			productPrice() {
				// return (this.price = "¥ " + this.item.item_price + " (税込)");
				return `¥ ${this.item.item_price}`;
				// console.log(this.item.item_price);
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "Card.scss";
</style>
