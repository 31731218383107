import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ProductListContent from '../components/ProductListContent.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/productlist',
    name: 'ProductList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "productlist" */ '../views/ProductLists.vue'),
    children: [
			{
			path: ":id",
			component: ProductListContent,
			},
		],
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {selector: to.hash, behavior:"smooth"}
  } else {
      return { x: 0, y: 0}
  }
}
})


export default router
