<template>
	<nav class="mobilenav">
		<ul class="mobilenav__list">
			<li class="mobilenav__items">
				<router-link to="/#products">PRODUCTS</router-link>
			</li>
			<li class="mobilenav__items">
				<router-link to="/#concept">CONCEPT</router-link>
			</li>
			<li class="mobilenav__items">
				<router-link to="/#ig">GALLERY</router-link>
			</li>
			<li class="mobilenav__items">
				<a
					href="mailto:mitchs.cookie@gmail.com"
					target="_blank"
					rel="noopener noreferrer"
					>CONTACT</a
				>
			</li>
		</ul>
	</nav>
</template>

<script>
	export default {
		name: "MobileNav",
	};
</script>

<style lang="scss" scoped>
	.mobilenav {
		font-size: 16px;
		width: 100vw;
		display: none;
		&__list {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 1fr 1fr;
		}
		&__items {
			border: 1px solid #5e4937;
			background-color: #241c17;
			a {
				font-size: 1em;
				color: #b88b50;
				font-weight: 800;
				display: flex;
				justify-content: center;
				text-decoration: none;
				padding: 20px 0px;
			}
		}
	}

	@media screen and (max-width: 768px) {
		.mobilenav {
			display: block;
		}
	}
	@media screen and (max-width: 375px) {
		.mobilenav {
			font-size: 12px;

			&__items {
				a {
					padding: 16px 0px;
				}
			}
		}
	}
</style>
