<template>
  <div id="app">
    <div class="app_header">
      <Header/>
    </div>
    <div class="app_body">
      <router-view/>
      <Footer />
    </div>
  </div>
  
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
export default {
  components: {
		Header,
    Footer,
}

};
</script>

<style lang="scss">
@media screen and (max-width: 800px) {
  .app_header {
    z-index: 3;
  }
  .app_body {
    z-index: 2;
  }
}

</style>
