<template>
<div>
        <div class="footer">               
                <div class= "footer__logo" id="footer__logo">
                        <img src="../assets/images/logo-1.svg" alt="" class="footer__img" @click="toTop()">
                </div>
                <div class="footer__info__copyright">
                        <div class="footer__copyright">
                                <p>Copyright &copy; 2022  菓子製造業許可取得 Mitch’s Cookie,  <wbr />All Rights Reserved.</p>
                        </div>
                </div>
        </div>
</div>
</template>

<script>
export default {
	name: "Footer",
        methods:{
                
                toTop() {
                        window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                        });
                },
        }
};
</script>

<style lang="scss" scoped>

@import "Footer.scss";

</style>