var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[(_vm.hasSubPage)?_c('router-link',{attrs:{"to":("/productlist/" + (_vm.item.id))}},[_c('div',{staticClass:"card__thumbnail"},[_c('img',{staticClass:"card__thumbnail__img",attrs:{"src":_vm.item.item_image.url,"alt":""}})]),_c('div',{staticClass:"card__info"},[_c('div',{staticClass:"card__info__content"},[_c('div',{staticClass:"card__tag ",class:{
							'card__tag--soldout': _vm.item.item_availability[0] === 'SOLD OUT',
							'card__item_availability[0]--soon':
								_vm.item.item_availability[0] === 'COMING SOON',
						}},[_vm._v(" "+_vm._s(_vm.item.item_availability[0])+" ")]),_c('div',{staticClass:"card__title"},[_vm._v(_vm._s(_vm.item.item_name))]),(_vm.item.item_description)?_c('div',{staticClass:"card__detail"},[_c('p',[_vm._v(_vm._s(_vm.item.item_description))])]):_vm._e(),(_vm.item.item_price)?_c('div',{staticClass:"card__price"},[_vm._v(" "+_vm._s(_vm.productPrice)+" ")]):_vm._e(),_c('div',{staticClass:"card__underline"})])])]):(this.item.item_url)?_c('a',{attrs:{"href":("" + (this.item.item_url)),"target":"_blank","rel":"noopener noreferrer"}},[_c('div',{staticClass:"card__thumbnail"},[_c('img',{staticClass:"card__thumbnail__img",attrs:{"src":_vm.item.item_image.url,"alt":""}})]),_c('div',{staticClass:"card__info"},[_c('div',{staticClass:"card__info__content"},[_c('div',{staticClass:"card__tag ",class:{
							'card__tag--soldout': _vm.item.item_availability[0] === 'SOLD OUT',
							'card__item_availability[0]--soon':
								_vm.item.item_availability[0] === 'COMING SOON',
						}},[_vm._v(" "+_vm._s(_vm.item.item_availability[0])+" ")]),_c('div',{staticClass:"card__title"},[_vm._v(_vm._s(_vm.item.item_name))]),(_vm.item.item_description)?_c('div',{staticClass:"card__detail"},[_c('p',[_vm._v(_vm._s(_vm.item.item_description))])]):_vm._e(),(_vm.item.item_price)?_c('div',{staticClass:"card__price"},[_vm._v(" "+_vm._s(_vm.productPrice)+" ")]):_vm._e(),_c('div',{staticClass:"card__underline"})])])]):_c('a',{attrs:{"target":"_blank","rel":"noopener noreferrer"}},[_c('div',{staticClass:"card__thumbnail"},[_c('img',{staticClass:"card__thumbnail__img",attrs:{"src":_vm.item.item_image.url,"alt":""}})]),_c('div',{staticClass:"card__info"},[_c('div',{staticClass:"card__info__content"},[_c('div',{staticClass:"card__tag ",class:{
							'card__tag--soldout': _vm.item.item_availability[0] === 'SOLD OUT',
							'card__item_availability[0]--soon':
								_vm.item.item_availability[0] === 'COMING SOON',
						}},[_vm._v(" "+_vm._s(_vm.item.item_availability[0])+" ")]),_c('div',{staticClass:"card__title"},[_vm._v(_vm._s(_vm.item.item_name))]),(_vm.item.item_description)?_c('div',{staticClass:"card__detail"},[_c('p',[_vm._v(_vm._s(_vm.item.item_description))])]):_vm._e(),(_vm.item.item_price)?_c('div',{staticClass:"card__price"},[_vm._v(" "+_vm._s(_vm.productPrice)+" ")]):_vm._e(),_c('div',{staticClass:"card__underline"})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }