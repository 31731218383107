<template>
	<div class="instagram" id="ig">
        <h1 class ="instagram__title">Instagram Gallery</h1>
            <div class="instagram__gallery">
                <div class="instagram__ImageList">
                    <InstagramImageList class="instagram__list" />
                </div>
            </div>
	</div>
</template>

<script>
import InstagramImageList from "./InstagramImageList.vue";

export default {
    name: "Instagram",
    components:{
        InstagramImageList
    },
    data(){
        return{
        }
    }
};
</script>

<style lang="scss" scoped>
@import "InstagramGallery.scss";
</style>