<template>
	<div id="products">
		<div class="title">
			<h1 class="title__text">Products</h1>
			<div class="title__line"></div>
			<!-- <p class="title__desc">{{detailText}}</p> -->
		</div>
		<CardList :isHomePage="isHomePage" />
	</div>
</template>

<script>
	import CardList from "./CardList.vue";
	export default {
		components: { CardList },
		name: "Products",
		props: {
			isHomePage: Boolean,
			detailText: String,
		},
		data() {
			return {};
		},
	};
</script>

<style lang="scss" scoped>
	@import "Products.scss";
</style>
