<template>
  <section class="shopinfo" id="shopinfo">
    <h1 class="shopinfo__title">Shop Info</h1>
    <div class="shopinfo__content">
      <div class="shopinfo__grid">
        <div class="shopinfo__locIcon">
          <i>
            <svg xmlns="http://www.w3.org/2000/svg" width="14.104" height="19.704" viewBox="0 0 14.104 19.704">
              <g id="location-outline" transform="translate(-111.25 -47.25)">
                <path id="Path_781" data-name="Path 781" d="M118.3,47.25A6.912,6.912,0,0,1,125.354,54c0,3.984-4.116,9.974-5.889,12.368a1.44,1.44,0,0,1-2.325,0C115.367,63.97,111.25,57.977,111.25,54A6.912,6.912,0,0,1,118.3,47.25Zm0,18.163c1.363-1.847,5.552-7.82,5.552-11.418a5.561,5.561,0,0,0-11.1,0C112.75,57.59,116.939,63.566,118.3,65.413Z" fill="#fff"/>
                <path id="Ellipse_289" data-name="Ellipse 289" d="M1.762-.75A2.512,2.512,0,1,1-.75,1.762,2.515,2.515,0,0,1,1.762-.75Zm0,3.524A1.012,1.012,0,1,0,.75,1.762,1.013,1.013,0,0,0,1.762,2.774Z" transform="translate(116.405 52.405)" fill="#fff"/>
              </g>
            </svg>
          </i>
        </div>
        <div class="shopinfo__address">
          <p>
            〒470-2359
          </p>
          <p>
            愛知県知多郡武豊町桜ケ丘2-70
          </p>
        </div>
      </div>
      <div class="shopinfo__link">
        <a href="https://goo.gl/maps/GWiBQ22UyS1Zissp9" target="_blank" rel="noopener noreferrer" class="shopinfo__linkitem">Google map 
          <span class="shop__linkicon">
            <i>
              <svg xmlns="http://www.w3.org/2000/svg" width="8.002" height="15.001" viewBox="0 0 8.002 15.001">
                <g id="Group_1482" data-name="Group 1482" transform="translate(-734.998 -2408.5)">
                  <path id="Union_1" data-name="Union 1" d="M-16929.437-11205.148a.5.5,0,0,1,0-.705l6.648-6.647-6.648-6.646a.5.5,0,0,1,0-.706.5.5,0,0,1,.711,0l7,7a.5.5,0,0,1,.148.354.5.5,0,0,1-.148.355l-7,7a.5.5,0,0,1-.354.149A.505.505,0,0,1-16929.437-11205.148Z" transform="translate(17664.582 13628.5)" fill="#fff"/>
                </g>
              </svg>
            </i>
          </span>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
@import './ShopInfo.scss';
</style>