<template>
	<div class="scrollIndicator">
		<div class="scrolldown1"><span>Scroll</span></div>
	</div>
</template>

<script>
	export default {
		name: "scrollIndicator",
	};
</script>

<style lang="scss" scoped>
	.scrolldown1 {
		position: absolute;
		left: 50%;
		bottom: 20px;

		height: 50px;
		z-index: 8000;
	}

	.scrolldown1 span {
		position: absolute;
		left: -22px;
		top: -15px;
		color: #fff;
		text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
		font-size: 1em;
		letter-spacing: 0.05em;
	}

	.scrolldown1::after {
		content: "";
		position: absolute;
		top: 0;

		width: 2px;
		height: 30px;
		background: #fff;
		box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
		animation: pathmove 1.4s ease-in-out infinite;
		opacity: 0;
	}

	@keyframes pathmove {
		0% {
			height: 0;
			top: 0;
			opacity: 0;
		}
		30% {
			height: 30px;
			opacity: 1;
		}
		100% {
			height: 0;
			top: 50px;
			opacity: 0;
		}
	}
</style>
