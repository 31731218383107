<template>
	<section class="concept" id="concept">
		<div class="concept__container">
			<div class="concept__img concept__img--left">
				<img src="../assets/images/concept/imageleft@2x.png" alt="" />
			</div>
			<div class="concept__content">
				<div class="concept__title">
					<h1>Concept</h1>
				</div>
				<div class="concept__subtitle">
					<h2>
						けっしてどこにもない魅惑の美味しさ。<br />「幸せのクッキー」をお召しください。
					</h2>
				</div>
				<div class="concept__paragraph">
					<p>
						愛知県知多郡武豊町にある、クッキー屋「Mitch’s Cookie」。<br />
						地元の方に愛されつづける当店は、素材をこだわりぬいたクッキーなど、<br />
						どなたが食べても暖かくて、幸せな気持ちになれるようなおいしさをコンセプトに、<br />こだわりのお菓子を取り揃えています。
					</p>
				</div>
			</div>
			<div class="concept__img concept__img--right">
				<img src="../assets/images/concept/merenge01.png" alt="" />
			</div>
		</div>
	</section>
</template>

<script>
	import axios from "axios";
	export default {
		name: "Concept",
		data() {
			return {
				conceptTitle: "",
				conceptDesc: "",
				conceptImg: "",
			};
		},
		mounted() {
			// let url = "https://mitchscookie.microcms.io/api/v1/concept";
			// let headers = {
			// 	"X-API-KEY": "cf769bde-e035-4456-bf6f-a898218d85c0",
			// };
			// axios({
			// 	method: "get",
			// 	url,
			// 	headers,
			// })
			// 	.then((res) => {
			// 		const { title, image, description } = res.data.contents[0];
			// 		this.conceptTitle = title;
			// 		this.conceptDesc = description;
			// 		this.conceptImg = image.url;
			// 	})
			// 	.catch((err) => console.log(err.message));
		},
	};
</script>

<style lang="scss" scoped>
	@import "Concept.scss";
</style>
