<template>
	<div class="home">
		<HomeContents />
		<FloatingButton v-if="isEnable"/>
	</div>
</template>

<script>
// @ is an alias to /src
import HomeContents from "../components/HomeContents.vue";
import FloatingButton from "../components/FloatButton.vue";
import axios  from "axios";
export default {
	name: "Home",
	components: {
		HomeContents,
		FloatingButton
  },
  data() {
    return {
      isEnable: false
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() { 
      const url = 'https://mitchscookie.microcms.io/api/v1/config'
      const headers = {
					"X-API-KEY": "cf769bde-e035-4456-bf6f-a898218d85c0",
				};
      axios({
        method: 'GET',
        url,
        headers,
      }).then(res => { 
        // console.log(res.data)
        res.data.contents.map(content => { 
          if (content.config_id === 'floating_btn') {
            this.isEnable = content.config_input
          }
        })
      }).catch(err => console.log(err))
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
