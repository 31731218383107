<template>
    <div>
        <div class="instagram__container">
            <div class="instagram__Imagelist">
                <div class="instagram__featured">
                    <a :href="featuredLink" target="_blank">
                        <img v-bind:src="featured" alt="" class="instagram__featuredImage__Big">
                    </a>
                </div>
                <div class="instagram__grid">
                    <div class="instagram__featuredContainer">
                        <a :href="featuredLink" target="_blank">
                            <img :src="featured" alt="" class="instagram__featuredImage__Mobile">
                        </a>
                    </div>
                    <div v-for="images in imageList" :key="images.id" class="instagram__loop" :href="imageList.link">
                        <a :href="images.link" target="_blank">
                            <img :src="images.image" class="instagram__image">
                        </a>  
                    </div>
                    <div class="instagram__ImageLastContainer">
                        <a :href="smileyLink" target="_blank">
                                <img :src="smiley" alt="" class="instagram__ImageLast">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "InstagramImageList",
    data(){
        return{
            featured: require("../assets/images/instagram/cookies2x.png"),
            featuredLink: "https://www.instagram.com/p/CEmGFkQjno8/",
            smiley: require("../assets/images/instagram/smiley2x.png"),
            smileyLink: "https://www.instagram.com/p/CFwddq_j19_/",

            iglogo: require("../assets/images/instagram/igLogo.png"),

            imageList:[
                {
                    id: "1st",
                    image: require("../assets/images/instagram/oneside2x.png"),
                    link: "https://www.instagram.com/p/CADMnbbjXMC/"
                },
                {
                    id: "2nd",
                    image: require("../assets/images/instagram/snoopy2x.png"),
                    link: "https://www.instagram.com/p/CFcBOK6jp0x/"
                },
                {
                    id: "3rd",
                    image: require("../assets/images/instagram/inatray2x.png"),
                    link: "https://www.instagram.com/p/CFcAStRjD73/"
                },
                {
                    id: "4th",
                    image: require("../assets/images/instagram/anime2x.png"),
                    link: "https://www.instagram.com/p/CFcByT_DCJI/"
                },

            ]
        }
    }
}
</script>
<style lang="scss" scoped>
@import "InstagramImageList.scss";
</style>