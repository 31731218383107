<template>
	<div>
		<div class="cardlist">
			<div class="cardlist__content">
				<div class="cardlist__grid">
					<div v-for="item in items" :key="item.id" class="cardlist__loop">
						<span class="cardlist__link">
							<Card :inHomePage="isHomePage" :item="item" />
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Card from "./Card.vue";
	import axios from "axios";
	export default {
		components: { Card },
		name: "CardList",
		props: {
			isHomePage: Boolean,
		},
		data() {
			return {
				items: [],
			};
		},
		mounted() {
			this.getData();
		},
		methods: {
			getData() {
				if (!this.isHomePage) {
					var url = `https://mitchscookie.microcms.io/api/v1/lineup/${this.$route.params.id}`;
				} else {
					url = "https://mitchscookie.microcms.io/api/v1/lineup?limit=999";
				}
				let headers = {
					"X-API-KEY": "cf769bde-e035-4456-bf6f-a898218d85c0",
				};
				axios({
					method: "get",
					url,
					headers,
				})
					.then((res) => {
						if (!this.isHomePage) {
							res.data.item_products.map((content) => {
								this.items.push(content);
							});
						} else {
							res.data.contents.map((content) => {
								this.items.push(content);
							});
						}
						this.isLoading = false;
					})
					.catch((err) => {
						this.error = true;
					});
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "CardList.scss";
</style>
