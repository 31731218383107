<template>
	<div class="content">
		<!-- <div class="header" v-show="!(window.width < 510 && scY > 350)"> -->
		<div class="header">
			<div class="header__nav">
				<nav class="header__nav__container">
					<ul class="header__nav__content">
						<li class="header__nav__btn">
							<router-link to="/#products">PRODUCTS</router-link>
						</li>
						<li class="header__nav__btn">
							<router-link to="/#concept">CONCEPT</router-link>
						</li>
						<li id="header__logo">
							<router-link to="/" class="logo">
								<img
									src="../assets/images/logo-1.svg"
									alt=""
									class="header__img"
								/>
							</router-link>
						</li>
						<li class="header__nav__btn">
							<router-link to="/#ig">GALLERY</router-link>
						</li>
						<li class="header__nav__btn">
							<a
								href="mailto:mitchs.cookie@gmail.com"
								target="_blank"
								rel="noopener noreferrer"
								>CONTACT</a
							>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Header",
		data() {
			return {
				scTimer: 0,
				scY: 0,
				window: {
					width: 0,
				},
			};
		},
		mounted() {
			window.addEventListener("scroll", this.handleScroll);
		},
		created() {
			window.addEventListener("resize", this.handleResize);
			this.handleResize();
		},
		destroyed() {
			window.removeEventListener("resize", this.handleResize);
		},
		methods: {
			// handleScroll() {
			//     if (this.scTimer) return;
			//         this.scTimer = setTimeout(() => {
			//         this.scY = window.scrollY;
			//         clearTimeout(this.scTimer);
			//         this.scTimer = 0;
			//     }, 100);
			// },
			toTop() {
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			},
			handleResize() {
				this.window.width = window.innerWidth;
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "Header.scss";
</style>
