<template>
	<div class="banner">
		<!-- <div class="banner__bg">
			<div class="banner__bg__img"></div>
		</div> -->

		<div class="slideshow">
			<div
				v-for="(bannerImage, i) in bannerImages"
				:key="i"
				class="slideshow-image banner__image-1"
				:style="{ backgroundImage: `url(${bannerImage})` }"
			></div>
		</div>
    <!-- <div class="carousel" >
      <div class="carousel__item" ref="kenburns" :class="{'kenburns-active' : activeEffect === i}" v-for="(bannerImage, i) in this.bannerImages" :key="i" :style="{ backgroundImage: `url(${bannerImage})`}">
        
      </div>
    </div> -->
		<div class="banner__scroll">
			<ScrollIndicator />
		</div>
	</div>
</template>

<script>
	import ScrollIndicator from "../components/ScrollIndicator.vue";
  import axios from "axios";
	export default {
		name: "Banner",
		components: {
      ScrollIndicator,
      
		},
		data() {
			return {
				show: true,
				scTimer: 0,
				scY: 0,
				window: {
					width: 0,
				},
        bannerImages: [],
        activeEffect: 0,
        kenburnsctr: 0,
        bannerImagesLenght:0
			};
		},
		mounted() {
			window.addEventListener("scroll", this.handleScroll);
      this.getBannerData();
      
    },
    updated() { 
      this.kenburns();
    },  
		created() {
			window.addEventListener("resize", this.handleResize);
			this.handleResize();
		},
		
		methods: {
			
			handleScroll() {
				if (this.scTimer) return;
				this.scTimer = setTimeout(() => {
					this.scY = window.scrollY;
					clearTimeout(this.scTimer);
					this.scTimer = 0;
				}, 100);
			},
			toTop() {
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			},
			getBannerData() {
				let url = `https://mitchscookie.microcms.io/api/v1/banner`;
				let headers = {
					"X-API-KEY": "cf769bde-e035-4456-bf6f-a898218d85c0",
				};
				axios({
					method: "get",
					url,
					headers,
				})
          .then((res) => {
						res.data.contents.forEach((el) => {
							if (this.window.width > 800) {
								this.bannerImages.push(el.desktop_image.url);
							} else {
								this.bannerImages.push(el.mobile_image.url);
              }
						});
						document
							.querySelector(":root")
              .style.setProperty("--banner-length", this.bannerImages.length);
            this.bannerImagesLenght = this.bannerImages.length
              // console.log(this.bannerImages)
					})
          .catch((err) => console.log(err));
          
          
			},
			handleResize() {
				this.window.width = window.innerWidth;
      },
      kenburns() { 
        // TODO: shouldn't expect setInterval and setTimeout to ever be accurate to match css keyframe animation
        setInterval(() => {
          if (this.kenburnsctr >= this.bannerImagesLenght) {
            this.kenburnsctr = 0
          }
          this.activeEffect = this.kenburnsctr
          this.kenburnsctr++
          
        }, 15000);
      }
      
  },
	};
</script>

<style lang="scss" scoped>
	@import "Banner.scss";
  .carousel__item{
    height: 100vh;
    width:100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;
    position: absolute;
    transform: scale(1.5);
    z-index: 1000;
    animation: none;
  }

  .kenburns-active{
    animation: kenburns 15000ms ease forwards;
    
  }

  @keyframes kenburns {
    0%{
      opacity: 1;
      z-index: 2000;
      transform: scale(1.5);
    }
    90%{
      opacity: 1;
      transform: scale(1)
    }
    100%{
      opacity: 0;
      z-index: 1000;
      transform: scale(1);
    }
  }
</style>
